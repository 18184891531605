<template>
    <div>
        <table class="py-5" v-if="type === 'flights'">
            <tr>
                <!-- <th>Title<v-divider class="my-2"></v-divider></th> -->
                <th>Name<v-divider class="my-2"></v-divider></th>
                <th>Birth Date<v-divider class="my-2"></v-divider></th>
                <th>Passport Number<v-divider class="my-2"></v-divider></th>
                <th>Expire Date<v-divider class="my-2"></v-divider></th>
                <th>Country<v-divider class="my-2"></v-divider></th>
            </tr>
            <tr v-for="(item, i) in items" :key="i">
                <!-- <td>{{item.passengerTitle}}<v-divider class="mt-2"></v-divider></td> -->
                <td>{{item.passengerFirstName}} {{item.passengerLastName}}<v-divider class="mt-2"></v-divider></td>
                <td>{{formatDate(item.date_of_birth.replace(/\//g, '-'), 'birth')}}<v-divider class="mt-2"></v-divider></td>
                <td>{{item.passport_number}}<v-divider class="mt-2"></v-divider></td>
                <td>{{formatDate(item.passport_expire_date)}}<v-divider class="mt-2"></v-divider></td>
                <td>{{item.passport_issue_country}}<v-divider class="mt-2"></v-divider></td>
                <v-divider></v-divider>
            </tr>
        </table>
        <table v-if="type === 'hotels'" border="1">
            <tr>
                <th>Room</th>
                <th>Title</th>
                <th>Name</th>
                <th>Age</th>
                <th>Leader</th>
            </tr>
            <tr v-for="(item, i) in items" :key="i">
                <td>{{item.InRoom}}</td>
                <td>{{item.title}}</td>
                <td>{{item.firstName}} {{item.lastName}}</td>
                <td>{{item.guestType}}</td>
                <td>{{item.isLead === 1 ? 'True' : ''}}</td>
            </tr>
        </table>
        <table v-if="type === 'cars'"></table>
    </div>
</template>

<script>
export default {
  props: ['type', 'items'],
  data () {
    return {
    }
  },
  methods: {
    formatDate (date, type) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const newDate = `${day} ${months[month - 1]} ${year}`
      if (type === 'birth') return newDate
      else return newDate
    }
  }
}
</script>

<style scoped>
    table {
        border: 1.5px solid #d7d7d7;
        text-align: center;
        width: 100%;
    }
</style>
